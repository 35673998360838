import {
  Container,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Tooltip,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Acai from "../images/acai.jpg";
import AcaiP from "../images/acaip.jpg";
import Tigela from "../images/tigela.jpg";
import Header from "./Header";
import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh"; // Ícone de refresh
import { format, subHours } from "date-fns";

const Pedido = () => {
  const [clientes, setClientes] = useState([]);
  const [estados, setEstados] = useState({});
  const [idsModificados, setIdsModificados] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mensagemSnackbar, setMensagemSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  const authHeader = useAuthHeader();
  const [contadorDiario, setContadorDiario] = useState(0);

  const handleImprimirPedido = (cliente) => {
    const {
      nomeCliente,
      bairroCliente,
      ruaCliente,
      numeroResidencia,
      pontoReferencia,
      numeroTelefone,
    } = cliente;
    let textoAImprimir = `Comanda: ${cliente.id}
______________________________
Cliente: ${nomeCliente}
Telefone: ${numeroTelefone}
Bairro: ${bairroCliente}
Rua: ${ruaCliente}
Casa: ${numeroResidencia}
Ponto de referência: ${pontoReferencia}
Forma de Pagamento: ${cliente.tipoPagamento}
Valor Total: R$${cliente.valorTotal}
Observações: ${cliente.observacoes}
______________________________
`;

    cliente.pedidos.forEach((pedido, index) => {
      const adicionaisArray = pedido.adicionais
        ? pedido.adicionais.split(", ")
        : [];
      textoAImprimir += `Copo: ${pedido.tamanhoAcai}
  ${adicionaisArray
    .map((adicional, index) => `${index === 0 ? "" : "  "}- ${adicional}`)
    .join("\n")} 
______________________________
`;
    });

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const content = iframe.contentWindow;
    content.document.open();
    content.document.write(`
    <style>
    @page {
      margin: 6mm; /* Margem de 0.5mm em todos os lados */
      size: 100% 100%; /* Tamanho da folha */
    }
    body {
      font-size: 1.9vh; /* Tamanho da fonte em relação à altura da viewport */
      margin: 0;
      padding: 2mm;
    }
    pre {
      margin: 0;
      padding: 0; /* Remover qualquer preenchimento interno */
      width: 100%;
      height: 100%;
      white-space: pre-wrap;
    }
    </style>
    <pre>${textoAImprimir}</pre>
    `);
    content.document.close();

    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    document.body.removeChild(iframe);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getClientes = async () => {
    const response = await axios.get("https://acaimaniadelivery.com:3001/clientes", {
      headers: {
        Authorization: authHeader,
      },
    });
    setClientes(response.data);
  };
  const handleDelete = async (id) => {
    console.log("delete");
    await axios.delete(`https://acaimaniadelivery.com:3001/clientes/${id}`, {
      headers: {
        Authorization: authHeader,
      },
    });
    getClientes();
    setMensagemSnackbar("Pedido(s) deletado(s)!");
    setSeveritySnackbar("error");
    setSnackbarOpen(true);
  };

  const handleChangeEstado = (estadoOriginal, estadoNovo, id) => {
    setEstados((prevEstados) => ({
      ...prevEstados,
      [id]: estadoNovo,
    }));

    if (estadoOriginal !== estadoNovo) {
      if (!idsModificados.includes(id)) {
        setIdsModificados((prevIds) => [
          ...prevIds,
          { id, estadoPedido: estadoNovo },
        ]);
      }
    } else {
      setIdsModificados((prevIds) =>
        prevIds.filter((prevId) => prevId.id !== id)
      );
    }
  };

  const handleAplicar = async () => {
    console.log(idsModificados);
    console.log(clientes);

    await axios.put("https://acaimaniadelivery.com:3001/clientes", idsModificados, {
      headers: {
        Authorization: authHeader,
      },
    });
    setIdsModificados([]);
    getClientes();
    setSeveritySnackbar("success");
    setMensagemSnackbar("Pedido(s) atualizado(s)!");
    setSnackbarOpen(true);
  };

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    const checkResetDiario = () => {
      const now = new Date();
      const resetTime = new Date();
      resetTime.setHours(23, 59, 0, 0);

      console.log("Current time:", now);
      console.log("Reset time:", resetTime);

      if (now >= resetTime) {
        console.log("Resetting contadorDiario...");
        setContadorDiario(0);
      }
    };

    const interval = setInterval(() => {
      checkResetDiario();
    }, 60000);

    checkResetDiario();

    return () => clearInterval(interval);
  }, []);

  const organizarClientesPorEstado = (estado) => {
    const clientesFiltrados = clientes.filter(
      (pedido) => pedido.estadoPedido === estado
    );

    if (clientesFiltrados.length === 0) {
      return null; // Retorna null se não houver clientes neste estado
    }
    return (
      <>
        <Typography
          variant="h5"
          marginTop="1rem"
          color="purple"
          sx={{
            display: "flex",
            justifyContent: "center",
            allignItems: "center",
          }}
        >
          {estado}
        </Typography>
        {clientes
          .filter((cliente) => cliente.estadoPedido === estado)
          .slice()
          .reverse()
          .map((cliente) => (
            <Container
              sx={{ marginTop: "1rem" }}
              component={Paper}
              elevation={3}
              key={cliente.id}
            >
              {/* Detalhes do cliente */}
              <Grid container>
                <Grid
                  container
                  item
                  marginTop="1rem"
                  sx={{
                    display: "flex",
                    allignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5" color="purple">
                    Nome do Cliente: {cliente.nomeCliente}
                  </Typography>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Grid item>
                    <Tooltip title="Excluir">
                      <IconButton onClick={() => handleDelete(cliente.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Imprimir">
                      <IconButton onClick={() => handleImprimirPedido(cliente)}>
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Telefone:</strong> {cliente.numeroTelefone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Bairro:</strong> {cliente.bairroCliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Rua:</strong> {cliente.ruaCliente}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Casa:</strong> {cliente.numeroResidencia}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Ponto de referência:</strong>{" "}
                    {cliente.pontoReferencia}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Forma de Pagamento: </strong>{" "}
                    {cliente.tipoPagamento}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Data de Criação:</strong>{" "}
                    {format(new Date(cliente.createdAt), "dd/MM/yyyy HH:mm:ss")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Tipo de entrega:</strong> {cliente.tipoEntrega}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Valor total: </strong> R$
                    {cliente.valorTotal}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Observações:</strong> {cliente.observacoes}
                  </Typography>
                </Grid>
              </Grid>
              {/* Pedidos do cliente */}
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                  color="purple"
                  sx={{
                    display: "flex",
                    allignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Pedidos
                </Typography>
              </Grid>
              {cliente.pedidos.map((pedido) => (
                <Box key={pedido.id}>
                  {/* Detalhes do pedido */}
                  <Box key={cliente.id}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginTop: "1rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">
                          <strong>Tamanho:</strong> {pedido.tamanhoAcai}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">
                            <strong>Adicionais:</strong> {pedido.adicionais}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">
                            <strong>Valor: R$ {pedido.valorPedido}</strong>
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-end">
                          <Grid item xs={12} sm={2}></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
              <FormControl
                fullWidth
                color="secondary"
                sx={{
                  marginBottom: "1rem",
                }}
              >
                <InputLabel>Estado pedido:</InputLabel>
                <Select
                  value={estados[cliente.id] || cliente.estadoPedido}
                  onChange={(e) =>
                    handleChangeEstado(
                      cliente.estadoPedido,
                      e.target.value,
                      cliente.id
                    )
                  }
                >
                  <MenuItem value="Espera">Espera</MenuItem>
                  <MenuItem value="Montando">Montando</MenuItem>
                  <MenuItem value="Pronto">Pronto</MenuItem>
                  <MenuItem value="A caminho">A caminho</MenuItem>
                  <MenuItem value="Entregue">Entregue</MenuItem>
                </Select>
              </FormControl>
            </Container>
          ))}
      </>
    );
  };

  return (
    <>
      <Header />
      {clientes.length === 0 ? (
        <Typography sx={{ marginTop: "7rem", marginLeft: "5rem" }}>
          Não há clientes :(
        </Typography>
      ) : (
        <Container sx={{ marginTop: "6rem" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Tooltip title="Atualizar lista">
                <IconButton color="secondary" onClick={getClientes}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleAplicar(idsModificados)}
                disabled={idsModificados.length === 0}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
          {organizarClientesPorEstado("Espera")}
          {organizarClientesPorEstado("Montando")}
          {organizarClientesPorEstado("Pronto")}
          {organizarClientesPorEstado("A caminho")}
          {organizarClientesPorEstado("Entregue")}
        </Container>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severitySnackbar}>
          {mensagemSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Pedido;
